import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { useState } from "react";
import { DialogModal, HPHButton, IconButton, InputField } from "veronica-ui-component/dist/component/core";

export interface IPreferenceModal {
    visible: boolean;
}

export const SaveNewPreferenceModal: React.FC<IPreferenceModal> = (props: IPreferenceModal) => {
    const { visible } = props;
    const [preferenceName, setPreferenceName] = useState('');
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const docEnquiryVM = useDocumentEnquiryVM();
    const userEmail = docEnquiryState.userEmail;
    const searchCriteria = docEnquiryState.searchCriteria;
    const enabledSearchCriteria = docEnquiryState.enabledSearchCriteria;

    const onConfirmClicked = () => {        
        docEnquiryVM.showLoading();
        docEnquiryVM.saveNewSearchPreference(userEmail, searchCriteria, enabledSearchCriteria, preferenceName).then(data => {
            docEnquiryVM.loadAllSearchPreference(userEmail).then(data => {
                docEnquiryVM.hideLoading();
            }).catch(error => {
                docEnquiryVM.hideLoading();
            })
        }).catch(error => {
            docEnquiryVM.hideLoading();
        })
    }
    
    return <DialogModal
        appendTo='self'
        showButton={false}
        visible={visible}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">NEW PREFERENCE</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={docEnquiryVM.closeConfirmModal} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">
                <InputField maxLength={999} label="Name" type="text" value={preferenceName} onChange={(e) => setPreferenceName(e.target.value)} />
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={onConfirmClicked} />
            </div>
        }
    />;
};
