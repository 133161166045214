import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useEffect, useMemo } from "react";
import { DialogModal, HPHButton, HPHCheckbox, IconButton, InputDropdown } from "veronica-ui-component/dist/component/core";
import CustomSettingPanel from "../Accordion/CustomSettingPanel";

const DocumentPrintModal: React.FC = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const docEnquiryVM = useDocumentEnquiryVM();

    const {selectedRows,isShowPrintPad,docPrintCriteria, dynamicOptions} = docEnquiryState;
    
    const MODAL_CONST = DocumentEnquiryConstant.Modal;

    const {printQueueDropdownOptions,fileLocationDropdownOptions} = dynamicOptions;


    useEffect(() => {
        isShowPrintPad && docEnquiryVM.loadModalDropdownOption();
    },[docEnquiryVM, isShowPrintPad])

    const onPrintClicked = () => {        
        docEnquiryVM.showLoading();
        docEnquiryVM.onReprint(selectedRows,docPrintCriteria).finally(() => {
            docEnquiryVM.hideLoading();
        })

    }


    const memoWithSupp = useMemo(() =>
        <>
        <div className='im-flex-row-item'>
            <HPHCheckbox
                label={"With Supporting Document"}
                selectedValues={docPrintCriteria.printWithSupp}
                onChange={(e) => docEnquiryVM.onModalCheckboxChange(e.checked,"printWithSupp")}
            />
        </div>
        </>
    , [docEnquiryVM, docPrintCriteria.printWithSupp])

    const memoInvPrinter = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown 
            label='' 
            width="150px"
            inputType="freeText" 
            placeholder="Printer"
            value={docPrintCriteria.invPrinter} 
            onChange={(e) => docEnquiryVM.onModalDropdownChange(e, 'invPrinter')}
            options={printQueueDropdownOptions}/>   
            </div>
    , [docEnquiryVM, docPrintCriteria.invPrinter, printQueueDropdownOptions])

    const memoInvFileLoc = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown 
            label='' 
            width="150px"
            inputType="freeText" 
            placeholder="File Location"
            value={docPrintCriteria.invFileLoc} 
            onChange={(e) => docEnquiryVM.onModalDropdownChange(e, 'invFileLoc')}
            options={fileLocationDropdownOptions}/>   
            </div>
    , [docEnquiryVM, docPrintCriteria.invFileLoc, fileLocationDropdownOptions])

    const memoSuppDocPrinter = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown 
            label='' 
            width="150px"
            inputType="freeText" 
            placeholder="Printer"
            value={docPrintCriteria.suppPrinter} 
            onChange={(e) => docEnquiryVM.onModalDropdownChange(e, 'suppPrinter')}
            options={printQueueDropdownOptions}/>   
            </div>
    , [docEnquiryVM, docPrintCriteria.suppPrinter, printQueueDropdownOptions])

    const memoSuppDocFileLoc = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown 
            label='' 
            width="150px"
            inputType="freeText" 
            placeholder="File Location"
            value={docPrintCriteria.suppFileLoc} 
            onChange={(e) => docEnquiryVM.onModalDropdownChange(e, 'suppFileLoc')}
            options={fileLocationDropdownOptions}/>   
            </div>
    , [docEnquiryVM, docPrintCriteria.suppFileLoc, fileLocationDropdownOptions])


    return <DialogModal style={{  minWidth:'650px', minHeight:'650px' }}
        appendTo='self'
        showButton={false}
        visible={isShowPrintPad}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">DOCUMENTS TO PRINT</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={docEnquiryVM.closeConfirmModal} tooltipDisable={true}/>
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">

                <CriteriaItemContainer>
                {memoWithSupp}
                </CriteriaItemContainer>   

                <CriteriaItemContainer>
                <span className="DocPrintLabel">{MODAL_CONST.PRINTER}</span>
                </CriteriaItemContainer> 

                <CriteriaItemContainer>
                <span className="DocPrintLabel">{MODAL_CONST.PRINTER_INV}</span>
                {memoInvPrinter}{memoInvFileLoc}
                </CriteriaItemContainer>   

                <CriteriaItemContainer>
                <span className="DocPrintLabel">{MODAL_CONST.PRINTER_SUPP}</span>
                {memoSuppDocPrinter}{memoSuppDocFileLoc}
                </CriteriaItemContainer>  

                <CriteriaItemContainer>
                    <CustomSettingPanel docEnquiryState={docEnquiryState}/>
                </CriteriaItemContainer>  

            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={'Print'} size={'Small'} theme={'Primary'} onClick={onPrintClicked}/>
            </div>
        }
    />;
};

export default memo(DocumentPrintModal);