import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { DocumentEnquiryConstant } from "./DocumentEnquiryConstant";

export const CriteriaDirectoryDropdownOption: DropdownProps[] = _.orderBy([
    { dropdownLabel: DocumentEnquiryConstant.Header.CO_VSL_VOY, value: 'coVslVoy', tagLabel: DocumentEnquiryConstant.Header.CO_VSL_VOY },
    { dropdownLabel: DocumentEnquiryConstant.Header.ETD_RANGE, value: 'etd', tagLabel: DocumentEnquiryConstant.Header.ETD_RANGE },
    { dropdownLabel: DocumentEnquiryConstant.Header.OPS_DATE_RANGE, value: 'opsDate', tagLabel: DocumentEnquiryConstant.Header.OPS_DATE_RANGE },
    { dropdownLabel: DocumentEnquiryConstant.Header.BILL_TO_COMPANY, value: 'billToCompList', tagLabel: DocumentEnquiryConstant.Header.BILL_TO_COMPANY },
    { dropdownLabel: DocumentEnquiryConstant.Header.CHARGE_TYPE, value: 'chgTypeList', tagLabel: DocumentEnquiryConstant.Header.CHARGE_TYPE },
    { dropdownLabel: DocumentEnquiryConstant.Header.STATE, value: 'stateList', tagLabel: DocumentEnquiryConstant.Header.STATE },
    { dropdownLabel: DocumentEnquiryConstant.Header.BILLING_TYPE, value: 'billingTypeList', tagLabel: DocumentEnquiryConstant.Header.BILLING_TYPE },
    { dropdownLabel: DocumentEnquiryConstant.Header.BILLING_CYCLE_CODE, value: 'billingCycleCode', tagLabel: DocumentEnquiryConstant.Header.BILLING_CYCLE_CODE },
    { dropdownLabel: DocumentEnquiryConstant.Header.INVOICE_DATE_RANGE, value: 'invDate', tagLabel: DocumentEnquiryConstant.Header.INVOICE_DATE_RANGE },
    { dropdownLabel: DocumentEnquiryConstant.Header.INV_CRE_NOTE_NO, value: 'invNo', tagLabel: DocumentEnquiryConstant.Header.INV_CRE_NOTE_NO },
    { dropdownLabel: DocumentEnquiryConstant.Header.PREVIEW_REF_NO, value: 'previewRefNo', tagLabel: DocumentEnquiryConstant.Header.PREVIEW_REF_NO },
    // { dropdownLabel: DocumentEnquiryConstant.Header.CNTR_NO, value: 'cntrList', tagLabel: DocumentEnquiryConstant.Header.CNTR_NO },
    { dropdownLabel: DocumentEnquiryConstant.Header.HANDLING_TML, value: 'handlingTmlList', tagLabel: DocumentEnquiryConstant.Header.HANDLING_TML },

    { dropdownLabel: DocumentEnquiryConstant.Header.CUST_CODE, value: 'customerCodeList', tagLabel: DocumentEnquiryConstant.Header.CUST_CODE },
    { dropdownLabel: DocumentEnquiryConstant.Header.BILL_CYCLE, value: 'billingCycleList', tagLabel: DocumentEnquiryConstant.Header.BILL_CYCLE },
    { dropdownLabel: DocumentEnquiryConstant.Header.GEN_DATE_RANGE, value: 'genDate', tagLabel: DocumentEnquiryConstant.Header.GEN_DATE_RANGE },
    { dropdownLabel: DocumentEnquiryConstant.Header.GEN_BY, value: 'generatedBy', tagLabel: DocumentEnquiryConstant.Header.GEN_BY },
    { dropdownLabel: DocumentEnquiryConstant.Header.ISSUED_BY, value: 'issuedBy', tagLabel: DocumentEnquiryConstant.Header.ISSUED_BY },
    { dropdownLabel: DocumentEnquiryConstant.Header.EXP_TYPE, value: 'issueByEdiList', tagLabel: DocumentEnquiryConstant.Header.EXP_TYPE },
    { dropdownLabel: DocumentEnquiryConstant.Header.PUB_WEB_SITE, value: 'publicAtWebSiteList', tagLabel: DocumentEnquiryConstant.Header.PUB_WEB_SITE },
    { dropdownLabel: DocumentEnquiryConstant.Header.APPR_DATE_RANGE, value: 'approvalDatetime', tagLabel: DocumentEnquiryConstant.Header.APPR_DATE_RANGE },
    { dropdownLabel: DocumentEnquiryConstant.Header.APPR_STATUS, value: 'approvalStatusList', tagLabel: DocumentEnquiryConstant.Header.APPR_STATUS },
], 'dropdownLabel')
