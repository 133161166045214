export interface DocumentEnquiryPrintCriteria {    
    printWithSupp: boolean,
    invPrinter: string,
    invFileLoc: string,
    suppPrinter?: string,
    suppFileLoc?: string,
    enableCustomSetting: boolean,
    amountDisplay?: string[],
    exportTypes?: string[],
    invOrigCopies?: number,
    suppDocCopies?: number,
    watermark?: 'Y' | 'N',

    [key: string]: string[] | string | boolean | number | null | undefined
}

export const EMPTY_DOCUMNET_PRINT_CRITERIA : DocumentEnquiryPrintCriteria ={
    printWithSupp: false,
    invPrinter: "",
    invFileLoc: "",
    enableCustomSetting: false
}