import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DocumentEnquiryProvider } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { DocumentEnquiryHeader } from "presentation/view/section/DocumentEnquiry/DocumentEnquiryHeader";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const DocumentEnquiryHeaderContainer = () => {
    return <ANAInfoWrapper permission={Permission.LOGIN}>
        <DocumentEnquiryProvider>
            <GridStyles/>
            <DocumentEnquiryHeader />
        </DocumentEnquiryProvider>
    </ANAInfoWrapper>;
};