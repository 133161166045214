
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { CriteriaDirectoryVoyageCheckboxOption } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { EMPTY_DOCUMENT_ENQUIRY_CRITERIA } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { DocumentEnquiryCriteriaCheckboxRelatedField as checkboxRelatedFields } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteriaCheckboxRelatedField";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { isNonDefaultValue } from "presentation/utils/searchCriteriaUtil";
import { convertDateToDateForComponent } from "presentation/utils/timeUtil";
import { useCallback, useMemo } from "react";
import { HPHGroupTitle, Tags } from "veronica-ui-component/dist/component/core";

export const DocumentEnquiryCriteriaPreviewVoyagePanel = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const searchCriteria = docEnquiryState.searchCriteria;
    const docEnquiryVM = useDocumentEnquiryVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryVoyageCheckboxOption.find(item => docEnquiryState.enabledSearchCriteria[item.key]), [docEnquiryState.enabledSearchCriteria])
    const isAllDefaultValue = useMemo(() => {
        const attributeField = CriteriaDirectoryVoyageCheckboxOption.filter(item => docEnquiryState.enabledSearchCriteria[item.key]).map(item => item.key)
        const isAllDefault = !attributeField.find((field) => {
            return isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, field)
        })
        return isAllDefault
    }, [searchCriteria, docEnquiryState.enabledSearchCriteria])  

    const handleRemoveSearchCriteira = useCallback((fieldName: string, isSearch?: boolean) => {
        docEnquiryVM.showLoading();
        docEnquiryVM.onRemoveSearchCriteria(fieldName, isSearch);
        docEnquiryVM.hideLoading();
    }, [docEnquiryVM]);
    const chargeConstant = ChargeConstant;

    const etdFromStr = convertDateToDateForComponent(searchCriteria.etdDateFrom);
    const etdToStr = convertDateToDateForComponent(searchCriteria.etdDateTo);

    return (
        (enabledCriteria && !isAllDefaultValue) ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle id='im-preview-tag-group-title' titleText='Voyage'/>
            <div className='im-charge-data-search-criteria-preview-tag-group'>
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'coVslVoy') && <Tags
                    label={`${chargeConstant.Charge.CO_VSL_VOY} : ${searchCriteria.co}/${searchCriteria.vsl}/${searchCriteria.voy}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('coVslVoy')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'etd') && <Tags
                    label={`${chargeConstant.Charge.ETD_RANGE} : ${etdFromStr} - ${etdToStr}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('etd')}
                />}
            </div>
        </div> : null
    );
};
