import { DocumentConst } from "constants/document/DocumentConst";
import { CriteriaDirectoryChargeCheckboxOption } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { EMPTY_DOCUMENT_ENQUIRY_CRITERIA } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { DocumentEnquiryCriteriaCheckboxRelatedField as checkboxRelatedFields } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteriaCheckboxRelatedField";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { isNonDefaultValue } from "presentation/utils/searchCriteriaUtil";
import { convertDateToDateForComponent } from "presentation/utils/timeUtil";
import { useCallback, useMemo } from "react";
import { HPHGroupTitle, Tags } from "veronica-ui-component/dist/component/core";

export const DocumentEnquiryCriteriaPreviewChargePanel = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const searchCriteria = docEnquiryState.searchCriteria;
    const docEnquiryVM = useDocumentEnquiryVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryChargeCheckboxOption.find(item => docEnquiryState.enabledSearchCriteria[item.key]), [docEnquiryState.enabledSearchCriteria])
    const isAllDefaultValue = useMemo(() => {
        const attributeField = CriteriaDirectoryChargeCheckboxOption.filter(item => docEnquiryState.enabledSearchCriteria[item.key]).map(item => item.key)
        const isAllDefault = !attributeField.find((field) => {
            return isNonDefaultValue(searchCriteria,EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields,  field)
        })
        return isAllDefault
    }, [searchCriteria, docEnquiryState.enabledSearchCriteria]);
    const docEnqConstant = DocumentEnquiryConstant;

    const handleRemoveSearchCriteira = useCallback((fieldName: string, isSearch?: boolean) => {
        docEnquiryVM.showLoading();
        docEnquiryVM.onRemoveSearchCriteria(fieldName, isSearch);
        docEnquiryVM.hideLoading();
    }, [docEnquiryVM]);

    const opsDateFromStr = convertDateToDateForComponent(searchCriteria.opsDateFrom);
    const opsDateToStr = convertDateToDateForComponent(searchCriteria.opsDateTo);

    const billingTypeStr = searchCriteria.billingTypeList?.map(key => DocumentConst.billingTypePolicy[key as unknown as keyof typeof DocumentConst.billingTypePolicy]).join(',');

    return (
        (enabledCriteria && !isAllDefaultValue) ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle id='im-preview-tag-group-title' titleText='Charge'/>
            <div className='im-charge-data-search-criteria-preview-tag-group'>
                {isNonDefaultValue(searchCriteria,EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'opsDate') && <Tags
                    label={`${docEnqConstant.Header.OPS_DATE_RANGE} : ${opsDateFromStr} - ${opsDateToStr}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('opsDate')}
                />}
                {isNonDefaultValue(searchCriteria,EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'billToCompList') && <Tags
                    label={`${docEnqConstant.Header.BILL_TO_COMPANY}  : ${searchCriteria.billToCompList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('billToCompList')}
                />}
                {isNonDefaultValue(searchCriteria,EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'chgTypeList') && <Tags
                    label={`${docEnqConstant.Header.CHARGE_TYPE}  : ${searchCriteria.chgTypeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('chgTypeList')}
                />}
                {isNonDefaultValue(searchCriteria,EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'billingCycleCode') && <Tags
                    label={`${docEnqConstant.Header.BILLING_CYCLE_CODE}  : ${searchCriteria.billingCycleCode}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('billingCycleCode')}
                />}
                {isNonDefaultValue(searchCriteria,EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'billingTypeList') && <Tags
                    label={`${docEnqConstant.Header.BILLING_TYPE}  : ${billingTypeStr}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('billingTypeList')}
                />}
                {isNonDefaultValue(searchCriteria,EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'billingCycleList') && <Tags
                    label={`${docEnqConstant.Header.BILL_CYCLE}  : ${searchCriteria.billingCycleList}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('billingCycleList')}
                />}    
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA,checkboxRelatedFields, 'handlingTmlList') && <Tags
                    label={`${docEnqConstant.Header.HANDLING_TML} : ${searchCriteria.handlingTmlList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('handlingTmlList')}
                />}                   
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'customerCodeList') && <Tags
                    label={`${docEnqConstant.Header.CUST_CODE} : ${searchCriteria.customerCodeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('customerCodeList')}
                />}         
            </div>
        </div> : null
    );
};
