
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { DocumentEnquiryCriteriaDirectoryPanel } from "../DocumentEnquiryCriteriaDirectoryPanel/DocumentEnquiryCriteriaDirectoryPanel";
import { DocumentEnquiryCriteriaPanel } from "./DocumentEnquiryCriteriaPanel/DocumentEnquiryCriteriaPanel";


export const DocumentEnquiryRightPanel = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const isFilterSearchCriteria = docEnquiryState.isFilterSearchCriteria;
    const isShowRightCriteriaPanel = docEnquiryState.isShowRightCriteriaPanel;

    return <div className='im-charge-data-search-right-panel'>
        {isShowRightCriteriaPanel && isFilterSearchCriteria && <><div className='im-charge-data-search-right-panel-title'>SEARCH CRITERIA</div><DocumentEnquiryCriteriaPanel /></>}
        {isShowRightCriteriaPanel && !isFilterSearchCriteria && <><div className='im-charge-data-search-right-panel-title'>CRITERIA DIRECTORY</div><DocumentEnquiryCriteriaDirectoryPanel /></>}
    </div>;
};
