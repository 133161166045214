import { CriteriaDirectoryDropdownOption } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryDropdownOption";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { HPHButton, InputDropdown } from "veronica-ui-component/dist/component/core";
import { DocumentEnquiryCriteriaCheckboxList } from "./DocumentEnquirySearchCriteriaCheckboxList";

export const DocumentEnquiryCriteriaDirectoryPanel = () => {
    const docEnquiryVM = useDocumentEnquiryVM();
    const [docEnquiryState] = useDocumentEnquiryTracked();

    return <div className='im-charge-data-search-criteria-panel'>
        <div className='im-charge-data-search-criteria-directory-content'>
            <InputDropdown
                field='dropdownLabel'
                mode='single'
                label=''
                onChange={(e) => docEnquiryVM.onCriteriaDirectoryDropdown(e)}
                options={CriteriaDirectoryDropdownOption}
                value={docEnquiryState.highlightedCheckboxKey}
                width={' calc(100% - 35px)'} />
            <DocumentEnquiryCriteriaCheckboxList />
        </div>
        <div className='im-charge-data-search-criteria-directory-buttons'>
            <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={docEnquiryVM.onResetCheckboxValue} />
            <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={docEnquiryVM.onSaveCheckboxValue} />
        </div>
    </div>;
};