import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { RemoteFileInfoRepository } from "./RemoteFileInfoRepo";

export const RemoteFileInfoRepoImpl = (): RemoteFileInfoRepository => {
    const url = '/v1/fileLoc';

    /**
     * Get remote file location
     * @returns 
     */
    const getFileLoc = async() : Promise<string[]> => {
        return axiosGetData(referenceAxiosInstance, url, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getFileLoc: getFileLoc,
    }
}