
import { PrintQueueEntity } from "domain/entity/Common/PrintQueueEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { PrintQueueRepository } from "./PrintQueue";

export const PrintQueueRepoImpl = (): PrintQueueRepository => {
    const url = '/v1/printQueue';
    const forComboxUrl = '/v1/printQueueForCombox';

    const getAllPrintQueues = async() : Promise<PrintQueueEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        })
        
    }

    const getPrintQueueByKey = async(key: string): Promise<PrintQueueEntity> => {
        return axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const getPrintQueueByType = async(type: string): Promise<PrintQueueEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${url}/PrintQueueType/${type}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const createNewPrintQueue = async(newData: PrintQueueEntity) : Promise<PrintQueueEntity> => {
        const result = await axiosPostData(referenceAxiosInstance, `${url}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update PrintQueue Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"PrintQueue ${newData.printQueueName}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updatePrintQueue = async(updatedData: PrintQueueEntity): Promise<PrintQueueEntity> => {
        const result = await axiosPutData(referenceAxiosInstance, `${url}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deletePrintQueueByKey = async(key: string): Promise<boolean> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const getPrintQueueForCombox = async(): Promise<PrintQueueEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${forComboxUrl}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    return {
        getAllPrintQueues: getAllPrintQueues,
        getPrintQueueByKey: getPrintQueueByKey,
        getPrintQueueByType: getPrintQueueByType,
        createNewPrintQueue: createNewPrintQueue,
        updatePrintQueue: updatePrintQueue,
        deletePrintQueueByKey: deletePrintQueueByKey,
        getPrintQueueForCombox: getPrintQueueForCombox,
    }
}