import { CriteriaDirectoryAllCheckboxOption, CriteriaDirectoryCheckboxOptionList } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { useEffect, useRef } from "react";
import { HPHCheckbox } from "veronica-ui-component/dist/component/core";

export const DocumentEnquiryCriteriaCheckboxList = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const docEnquiryVM = useDocumentEnquiryVM();
    const checkBoxRef = useRef<{ [key: string]: HTMLElement | null }>({});

    useEffect(() => {
        docEnquiryVM.updateCheckboxRef(checkBoxRef.current);
    }, [docEnquiryVM]);

    const highlightCheckboxKey = docEnquiryState.highlightedCheckboxKey;
    const checkboxValue = docEnquiryState.checkboxValue;

    return <div className='im-charge-data-search-criteria-directory-checkbox-list'>
        <HPHCheckbox label={CriteriaDirectoryAllCheckboxOption.name} checked={checkboxValue[CriteriaDirectoryAllCheckboxOption.key]} onChange={(e) => docEnquiryVM.updateCheckboxValue(e.target.checked, CriteriaDirectoryAllCheckboxOption.key)} />
        {
            CriteriaDirectoryCheckboxOptionList.map(item => <div key={item.option.key} className='im-charge-data-search-criteria-directory-checkbox-group'>
                {
                    item.checkboxList?.map(item => <div ref={ref => checkBoxRef.current[item.key] = ref} key={item.key} className={`im-charge-data-search-criteria-directory-checkbox-child ${highlightCheckboxKey === item.key ? 'im-charge-data-search-criteria-directory-checkbox-child-highlighted' : ''}`}>
                        <HPHCheckbox label={item.name} checked={checkboxValue[item.key]} onChange={(e) => docEnquiryVM.updateCheckboxValue(e.target.checked, item.key)} />
                    </div>)
                }
            </div>)
        }
    </div>;
};
