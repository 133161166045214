import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_DOCUMENT_ENQUIRY_COL_DEF, transferRowData } from "presentation/constant/DocumentEnquiry/DocumentEnquiryColumnDefinition";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import { HPHTable, IconButton, Loader } from "veronica-ui-component/dist/component/core";


export const DocumentEnquiryTablePanel = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const isShowRightCriteriaPanel = docEnquiryState.isShowRightCriteriaPanel;
    const docEnquiryVM = useDocumentEnquiryVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();
    const tableDoubleClicked = (data:any) => {
        // if (data) {
        //     customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_REDIRECT_EVENT, 
        //         E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER, 
        //         { userName: anaInfoState.userName,
        //             data: data.data,
        //         }
        //     );
        // }
        
        docEnquiryVM.openContainerDetail(data.data);
    }

    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_DOCUMENT_ENQUIRY_COL_DEF.slice());
        
        // if (!docEnquiryState.selectedRows ||
        //     docEnquiryState.selectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        docEnquiryVM.updateSelectedRows(selectedRows);
      }, [docEnquiryVM])

    return <div className={`table-ag-gird im-charge-data-search-table-panel ag-theme-alpine`} style={{ position: 'relative' }}>
        <div className={`im-charge-data-search-table-panel-content ag-theme-alpine`}>
            {docEnquiryState.isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <HPHTable
                id='document-enquiry-table'
                columns={INITIAL_DOCUMENT_ENQUIRY_COL_DEF.slice()}
                cacheBlockSize={30}
                isNewColumnSetting={true}
                isScrollHighlighted={true}
                showPaginator={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                showUploadIcon={true}
                gridHeight="customHeight"
                customHeight={"calc(100% - 10px)"}
                onRowDoubleClick={(e:any) => tableDoubleClicked(e)}                
                selectionMode={false}
                rowSelection={"multiple"}
                suppressRowClickSelection={false}
                onSelectionChanged={handleSelectionChange}
                rowHeight={28}
                rowBuffer={20}
                ref={gridRef}
                data={transferRowData(docEnquiryState.docHdrs??[])}

                columnSettingIconList={
                    <>
                        <IconButton fileName={isShowRightCriteriaPanel ? "Icon-angle-left" : "Icon-angle-right"} size="medium" toolTipText={isShowRightCriteriaPanel ? "Close Right Panel" : "Open Right Panel"} toolTipArrow={false} onClick={docEnquiryVM.onHideRightCriteriaPanel} />
                    </>
                }
            />
        </div>
    </div>;
}