import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { useState } from "react";
import { DialogModal, DropdownOptions, HPHButton, IconButton, InputDropdown } from "veronica-ui-component/dist/component/core";
import { IPreferenceModal } from "./SaveNewPreferenceModal";

export const DeletePreferenceModal: React.FC<IPreferenceModal> = (props: IPreferenceModal) => {
    const { visible } = props;
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const docEnquiryVM = useDocumentEnquiryVM();
    const [preferenceKey, setPreferenceKey] = useState('');
    const userEmail = docEnquiryState.userEmail;

    const onConfirmClicked = () => {        
        docEnquiryVM.showLoading();
        docEnquiryVM.deleteSearchPreference(userEmail, preferenceKey).then(data => {
            docEnquiryVM.loadAllSearchPreference(userEmail).then(data => {
                docEnquiryVM.hideLoading();
            }).catch(error => {
                docEnquiryVM.hideLoading();
            })
        }).catch(error => {
            docEnquiryVM.hideLoading();
        })
    }

    return <DialogModal
        appendTo='self'
        showButton={false}
        visible={visible}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">DELETE PREFERENCE</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={docEnquiryVM.closeConfirmModal} tooltipDisable={true}/>
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">
                <InputDropdown label='Name' inputType="freeText" value={preferenceKey} onChange={(e: DropdownOptions) => setPreferenceKey(e?.value ?? '')}
                    options={docEnquiryState.searchPreferences.filter((searchPreference) => searchPreference.userEmail === userEmail).map(searchPreference => ({
                        dropdownLabel: searchPreference.preferenceName,
                        tagLabel: searchPreference.preferenceName,
                        value: searchPreference.preferenceName,
                    }))}/>             
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={onConfirmClicked}/>
            </div>
        }
    />;
};
