import { ChargeDataEnquiryRepoImpl } from "domain/repository/ChargeDataEnquiry/ChargeDataEnquiryRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { PrintQueueRepoImpl } from "domain/repository/Common/PrintQueueRepoImpl";
import { RemoteFileInfoRepoImpl } from "domain/repository/Common/RemoteFileInfoRepoImpl";
import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { DocumentEnquiryRepoImpl } from "domain/repository/Document/DocumentEnquiryRepoImpl";
import { DocumentPreviewRepoImpl } from "domain/repository/DocumentPreview/DocumentPreviewRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { SpecialHandlingIndMappingRepoImpl } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { DocumentEnquiryVM } from "presentation/viewModel/DocumentEnquiry/DocumentEnquiryVM";
import { useMemo } from "react";

export const useDocumentEnquiryVM = () => {
    const [, setDocumentEnquiryState] = useDocumentEnquiryTracked();
    const documentEnquiryVM = useMemo(() =>
        DocumentEnquiryVM({
            dispatch: [setDocumentEnquiryState],
            companyRepo: CompanyRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepoImpl(),
            chargeDataEnquiryRepo: ChargeDataEnquiryRepoImpl(),
            searchPreferenceRepo: SearchPreferenceRepoImpl(),
            customerRepo: CustomerRepoImpl(),
            docEnquiryRepo: DocumentEnquiryRepoImpl(),
            printQueueRepo: PrintQueueRepoImpl(),
            remoteFileInfoRepo: RemoteFileInfoRepoImpl(),
            docPreviewRepo: DocumentPreviewRepoImpl(),
        }), [setDocumentEnquiryState])

    return documentEnquiryVM
}