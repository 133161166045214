//When we disable the search criteria, it should also reset these search criteria value.
//This file is to keep mapping between the search criteria checkbox value and related field
//when disable 'coVslVoy', we need to clear field 'co','vsl','voy'

export const DocumentEnquiryCriteriaCheckboxRelatedField: { [key: string]: string[] } = {
    coVslVoy: ['co', 'vsl', 'voy'],
    etd: ['etdDateFrom', 'etdDateTo'],
    opsDate: ['opsDateFrom', 'opsDateTo'],
    invDate: ['invDateFrom', 'invDateTo'],
    genDate: ['genDateFrom', 'genDateTo'],
    approvalDatetime: ['approvalDatetimeFrom', 'approvalDatetimeTo'],
}