import { issueTypeCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { DocumentEnquiryModel } from "presentation/model/DocumentEnquiry/DocumentEnquiryModel";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useMemo } from "react";
import { GroupCheckboxList, HPHCheckbox, HPHGroupCheckbox, InputField } from "veronica-ui-component/dist/component/core";

const CustomSettingForm = ({docEnquiryState}:{docEnquiryState:DocumentEnquiryModel}) => {

    const docEnquiryVM = useDocumentEnquiryVM();

    const {docPrintCriteria} = docEnquiryState;
    const MODAL_CONST = DocumentEnquiryConstant.Modal;

    const memoAmtDisplay = useMemo(() =>
        <>
        <div className='im-flex-row-item'>
            {/* <HPHGroupCheckbox
                label={''}
                checkboxData={[
                    {key:"Y", name:"Amount Display", disabled:false}
                ]}
                selectedValues={docPrintCriteria.amountDisplay}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => docEnquiryVM.onModalGroupCheckboxChange(e, "amountDisplay")}
            /> */}

            <HPHCheckbox
                label={"Amount Display"}
                selectedValues={docPrintCriteria.amountDisplay}
                onChange={(e) => docEnquiryVM.onModalCheckboxChange(e.checked,"amountDisplay")}
            />
        </div>
        </>
    , [docEnquiryVM, docPrintCriteria?.amountDisplay])

    const memoExportType = useMemo(() =>
        <>
        <div className='im-flex-row-item'>
            <HPHGroupCheckbox
                label={MODAL_CONST.EXPORT}
                checkboxData={issueTypeCheckboxOption}
                selectedValues={docPrintCriteria.exportTypes}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => docEnquiryVM.onModalGroupCheckboxChange(e, "exportTypes")}
            />
        </div>
        </>
    , [MODAL_CONST.EXPORT, docPrintCriteria.exportTypes, docEnquiryVM])

    const memoOrigCopy = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={MODAL_CONST.ORIG_COPY}
                type="text"
                value={docPrintCriteria.invOrigCopies || ''}
                onChange={(e: any) => docEnquiryVM.onModalInputTextChange(e, 'invOrigCopies')}/>
            </div>
    , [MODAL_CONST.ORIG_COPY, docEnquiryVM, docPrintCriteria.invOrigCopies])

    const memoSuppCopy = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={MODAL_CONST.SUPP_COPY}
                type="text"
                value={docPrintCriteria.suppDocCopies || ''}
                onChange={(e: any) => docEnquiryVM.onModalInputTextChange(e, 'suppDocCopies')}/>
            </div>
    , [MODAL_CONST.SUPP_COPY, docEnquiryVM, docPrintCriteria.suppDocCopies])

    // const memoWatermark = useMemo(() =>
    //     <div className='im-flex-row-item'>
    //         <InputField
    //             width='150px'
    //             maxLength={999}
    //             label={MODAL_CONST.WATERMARK}
    //             type="text"
    //             value={docPrintCriteria.watermark || ''}
    //             onChange={(e: any) => docEnquiryVM.onModalInputTextChange(e, 'watermark')}/>  
    //         </div>
    // , [MODAL_CONST.WATERMARK, docEnquiryVM, docPrintCriteria.watermark])

    return (<>

        <CriteriaItemContainer>
            {memoAmtDisplay}
        </CriteriaItemContainer>  

        <CriteriaItemContainer>
            {memoExportType}
        </CriteriaItemContainer>  

        <CriteriaItemContainer>
        {memoOrigCopy} {memoSuppCopy}
        </CriteriaItemContainer>  
    </>)
}

export default memo(CustomSettingForm);

