import { DocumentEnquiryModel } from "presentation/model/DocumentEnquiry/DocumentEnquiryModel";
import { memo, useMemo } from "react";
import { Accordion } from "veronica-ui-component/dist/component/core";
import CustomSettingForm from "./CustomSettingForm";

const CustomSettingPanel = ({docEnquiryState}:{docEnquiryState:DocumentEnquiryModel}) => {

    const accordionData = useMemo(() => {
        const accordionData = [];
        accordionData.push({
            id: 'custom setting document print',
            header: 'Custom Setting',
            content: <CustomSettingForm docEnquiryState={docEnquiryState} />,
            enableToggle: docEnquiryState.docPrintCriteria.enableCustomSetting,
            key: "customSetting"
        })
        return accordionData;
    },[docEnquiryState])

    return (<>
        <div className='flex-row-item flex-row-item-full-width'  >
            <Accordion accordionData={accordionData} 
             width="500px" 
             toggle
             onChange={()=>{}}/>
        </div>
    </>)
}

export default memo(CustomSettingPanel);

