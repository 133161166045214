import { E_Custom_Dispatch_Event, E_Type_Of_Event, customEventListener } from "helpers/Events";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { DocumentEnquiryDetailContainer } from "presentation/view/container/DocumentEnquiry/DocumentEnquiryDetailContainer";
import { useEffect, useMemo } from "react";
import { Loader, ResizablePanel } from "veronica-ui-component/dist/component/core";
import { DeletePreferenceModal } from "./ConfirmModal/DeletePreferenceModal";
import DocumentPrintModal from "./ConfirmModal/DocumentPrintModal";
import { SaveNewPreferenceModal } from "./ConfirmModal/SaveNewPreferenceModal";
import { DocumentEnquiryRightPanel } from "./DocumentEnquiryPanel/DocumentEnquiryRightPanel";
import { DocumentEnquiryTablePanel } from "./DocumentTablePanel/DocumentEnquiryTablePanel";

export function isSearchCriteriaSet(obj1: any, obj2: any) { return JSON.stringify(obj1) === JSON.stringify(obj2); }

export const DocumentEnquiryComp : React.FC = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const searchCounter = docEnquiryState.searchCounter;
    const isShowContainerDetail = docEnquiryState.isShowContainerDetail;
    const isEditSearchCriteria = docEnquiryState.isEditSearchCriteria;
    const isShowRightCriteriaPanel = docEnquiryState.isShowRightCriteriaPanel;
    const [ anaInfoState ] = useANAInfoTracked();

    const docEnquiryVM = useDocumentEnquiryVM();

    useEffect(() => {
        if (anaInfoState.userName) {
            docEnquiryVM.setUserEmail(anaInfoState.userName)
        }
    }, [docEnquiryVM, anaInfoState.userName])

    useEffect(() => {
        docEnquiryVM.showLoading();
        const initialScreen = async() => {
            try {
                const results = await Promise.allSettled([
                    docEnquiryVM.loadAllSearchPreference(anaInfoState.userName),
                    docEnquiryVM.loadDropdownOption(),
                ])
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        console.log(`Load all search preference successful!`);
                      } else if (index === 1 && result.status === 'fulfilled') {
                        console.error(`Load dropdown options successful!`);
                      }
                })
            } catch (error) {
            }
        }
        initialScreen().then((data) => {            
            docEnquiryVM.hideLoading();
        }).catch(error => {            
            docEnquiryVM.hideLoading();
        });
    }, [anaInfoState.userName, docEnquiryVM]);



    const memoDocumentEnquiryTablePanel = useMemo(() => <DocumentEnquiryTablePanel />, []);
    const memoDocumentEnquiryRightPanel = useMemo(() => <DocumentEnquiryRightPanel />, []);

    const memoResizablePanel = useMemo(() =>
        <ResizablePanel
            id={searchCounter === 0 ? 'im-resizable-panel-collapsed' : `im-resizable-panel${!isEditSearchCriteria ? '-active' : ''}`}
            draggable={false}
            leftChildren={memoDocumentEnquiryTablePanel}
            rightChildren={memoDocumentEnquiryRightPanel}
            rightSectionWidth={searchCounter === 0 ? '100%' : '45%'}
            leftSectionWidth={searchCounter === 0 ? '0%' : '100%'}
            containerWidth={'100%'} />
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [searchCounter, memoDocumentEnquiryTablePanel, memoDocumentEnquiryRightPanel, isEditSearchCriteria])

    


    // Charge Data search event listner
    const onDocumentEnquiryChange = (e: any) => {
        switch (e.detail.action) {
            case E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_EDIT_CRITERIA:
                docEnquiryVM.onEditSearchCriteria();
                break;
            case E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_OPEN_DIRECTORY:
                docEnquiryVM.onSwitchSearchCriteriaMode();
                break;
            case E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_SAVE_SEARCH:
                docEnquiryVM.saveSearchPreference(e.detail.data.currentSearchPreference, docEnquiryState.searchCriteria, docEnquiryState.enabledSearchCriteria);
                break;
            case E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_SAVE_NEW:
                docEnquiryVM.openSaveNewConfirmModal();
                break;
            case E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_DELETE:
                docEnquiryVM.openDeleteConfirmModal();
                break;
            case E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_OPEN:
                docEnquiryVM.loadSearchPreference(e.detail.data.searchPreference);
                break;                
            case E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PRINT:
                docEnquiryVM.showPrintPad();
                break;
            case E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_MODAL_BACK_TO_LIST:
                docEnquiryVM.closeContainerDetail();
                break;
        }
    }

    useEffect(() => {
        customEventListener(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, onDocumentEnquiryChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    
    if (docEnquiryState.isLoading) return <Loader Indicator="Stripe" size="Large" />

    return <div className={`charge-data-box${((searchCounter > 0 && !isShowRightCriteriaPanel) || isShowContainerDetail) ? ' no-filter-preview' : ' filter-preview'}${isShowContainerDetail ? ' show-detail' : ''}`}>
        {/* Show container search panel */}
        <div style={{ display: !isShowContainerDetail ? 'block' : 'none' }}>{memoResizablePanel}</div>
        {/* Show container detail page */}

        {isShowContainerDetail && <DocumentEnquiryDetailContainer/>}

        <div className='im-charge-data-search-confirm-modal-container'>
            <SaveNewPreferenceModal visible={docEnquiryState.isShowSaveNewConfirmModal} />
            <DeletePreferenceModal visible={docEnquiryState.isShowDeleteConfirmModal} />
            { <DocumentPrintModal/> }

        </div>
    </div>;
}